import { Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const MainContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  margin: '0 1.3125rem',
}));

const PageTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontFamily: 'Open Sans',
  fontWeight: '400',
  lineHeight: '2.063rem',
  color: theme.palette.text.primary,
  margin: '0.8125rem 0',
}));

const SettingsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.layoutBackgroundColor,
  border: `0.313rem solid ${theme.palette.background.layoutBackgroundColor}`,
  borderRadius: '0.25rem 0.25rem 0 0',
  height: '100%',
}));

export { MainContainer, PageTitle, SettingsContainer };
