import { PAGE_TITLE_UI_SETTINGS } from '../../constants/index.js';
import UISettings from '../UISettings';
import { MainContainer, PageTitle, SettingsContainer } from './style.js';

export default function ConfigContainer() {
  return (
    <MainContainer data-testid="app-main-container">
      <PageTitle variant="h3" data-testid="app-page-title">
        {PAGE_TITLE_UI_SETTINGS}
      </PageTitle>
      <SettingsContainer>
        <UISettings />
      </SettingsContainer>
    </MainContainer>
  );
}
