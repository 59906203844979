import { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { globalstate$ } from '@smf/ui-util-app';
import { IconButton } from '@mui/material';
import InfoIcon from '../../assets/img/InfoTooltipIcon.svg';
import Accordion from '../../components/SMFAccordion';
import Button from '../../components/Button';
import * as S from './style';
import SMFSwitch from '../../components/SMFSwitch';
import {
  ACCORDIAN_LABELS,
  ACCORDIAN_TITLES,
  BUTTON_TEXT,
  TOAST_REDUCER_CONSTANTS,
} from '../../constants';
import { useToastContext } from '../../context/toastContext';
import MESSAGE_STRINGS from '../../constants/en-us';
import { updateSpcConfig } from '../../utils/apiHelpers';
import { updateRxjsState, useRxjsState } from '../../hooks/useRxjsState';

export default function UISettings() {
  const { AccordionDetails, AccordionSummary } = Accordion;
  const { toastDispatch } = useToastContext();
  const { rxjsState } = useRxjsState();
  const [status, setStatus] = useState(
    globalstate$.value?.configStatus?.spc ?? false,
  );
  const [disableSave, setDisableSave] = useState(false);
  const { plantId } = rxjsState;

  // Update Status After common status api call
  useEffect(() => {
    setStatus(globalstate$.value?.configStatus?.spc ?? false);
  }, [rxjsState?.configStatus?.spc]);

  // Disable Save Logic
  useEffect(() => {
    setDisableSave(rxjsState?.configStatus?.spc === status);
  }, [rxjsState?.configStatus?.spc, status]);

  // Update API Calling
  const { mutate: updateSpcConfigAPI, isLoading: updateSpcConfigLoading } =
    useMutation(
      ['update-pm-config'],
      async (payload) => {
        const result = await updateSpcConfig(payload);
        return result;
      },
      {
        onSuccess: (_, variables) => {
          // update spc status in Rxjs State
          updateRxjsState({
            configStatus: {
              ...rxjsState?.configStatus,
              spc: variables.status,
            },
          });
          // Success Toaster
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_SUCCESS_TOAST,
            payload: {
              message: MESSAGE_STRINGS.TOAST_SUCCESS_MESSAGE,
            },
          });
        },
        onError: (err) => {
          toastDispatch({
            type: TOAST_REDUCER_CONSTANTS.SHOW_ERROR_TOAST,
            payload: {
              message: MESSAGE_STRINGS.TOAST_ERROR_MESSAGE,
            },
          });
          console.error('Error While Updating SPC Config', err);
        },
        enabled: !!plantId,
      },
    );

  const onSaveClick = () => {
    updateSpcConfigAPI({ factoryId: plantId, status, module: 'spc' });
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary>{ACCORDIAN_TITLES.UI_SETTINGS}</AccordionSummary>
      <AccordionDetails>
        <S.ConfigRow>
          <S.OuterFlexContainer>
            <S.InnerFlexContainer>
              <S.InfoIconWrapper>
                <S.StyledTooltip
                  title={MESSAGE_STRINGS.SPC_ENABLE_TOOLTIP}
                  data-testid="spc-config-tooltip"
                  placement="bottom-start"
                >
                  <IconButton sx={{ paddingLeft: 0, paddingRight: 0 }}>
                    <InfoIcon
                      height="1.5rem"
                      width="1.5rem"
                      data-testid="spc-config-enable-toolip-icon"
                    />
                  </IconButton>
                </S.StyledTooltip>
              </S.InfoIconWrapper>
              <S.Description>{ACCORDIAN_LABELS.ENABLE_SPC}</S.Description>
              <S.SwitchContainer>
                <SMFSwitch
                  checked={status}
                  onChange={() => {
                    setStatus((previousState) => !previousState);
                  }}
                />
              </S.SwitchContainer>
            </S.InnerFlexContainer>
          </S.OuterFlexContainer>
        </S.ConfigRow>
        <S.SaveButtonContainer data-testid="saveButton">
          <Button
            onClick={onSaveClick}
            disabled={updateSpcConfigLoading || disableSave}
          >
            {BUTTON_TEXT.SAVE}
          </Button>
        </S.SaveButtonContainer>
      </AccordionDetails>
    </Accordion>
  );
}
