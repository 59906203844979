const MESSAGE_STRINGS = {
  ADD_REMOVE: 'Add / Remove',
  ADD_NOTIFICATIONS: 'Add Notifications',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  UPDATE: 'Update',
  EDIT: 'Edit',
  EMPTY_ACTIVE_NOTIFICATIONS: 'Activated Notifications will display here.',
  REQUIRED_FIELD: 'Required Field',
  NO_DATA_PRESENT: 'No Data Present',
  NO_UNSAVED_CHANGES: 'No Unsaved Changes',
  FUNCTIONAL_AREA: 'Functional Area',
  ROLES_ASSIGNED: 'Roles Assigned',
  RECURRENCE: 'Recurrence',
  ADDITIONAL_SETTINGS: "Addit'l Settings",
  MUTE: 'Mute',
  TOAST_SUCCESS_MESSAGE: 'Changes have been saved successfully!',
  TOAST_ERROR_MESSAGE: 'Something went wrong. Please try again later!',
  SELECT: 'Select',
  SELECT_ROLES: 'Select Roles',
  ROLES: 'Roles',
  MULTIPLE: 'Multiple',
  ALL: 'All',
  APPLY: 'Apply',
  RESET: 'Reset',
  OPERATIONAL_AREA: 'Operational Area',
  FILTER: 'Filter',
  HOURS: 'Hours',
  MINUTES: 'Minutes',
  SECONDS: 'Seconds',
  DURATION: 'Duration',
  HYPHEN: '-',
  TIME_UNIT_ARRAY: ['Hours', 'Minutes', 'Seconds'],
  SPC_ENABLE_TOOLTIP:
    'Please contact your system admin before enabling this feature.',
};

export default MESSAGE_STRINGS;
