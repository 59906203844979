import { Button as ButtonComponent, styled } from '@mui/material';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../constants';

export const Button = styled(ButtonComponent, {
  shouldForwardProp: (prop) => prop !== 'buttonSize' && prop !== 'buttonType',
})(({ theme, buttonType, buttonSize }) => {
  const isPrimary = buttonType === BUTTON_TYPE.PRIMARY;
  const isStandard = buttonSize === BUTTON_SIZE.STANDARD;
  return {
    height: isStandard ? '2.25rem' : '1.5rem', // As per figma
    margin: theme.spacing(0.5),

    color: isPrimary
      ? theme.palette.text.secondary
      : theme.palette.text.primary,
    border: isPrimary
      ? `0.0625rem solid ${theme.palette.background.saveGreen}`
      : `0.0625rem solid ${theme.palette.text.primary}`,
    boxSizing: 'border-box',
    borderRadius: '1.25rem',
    minWidth: isStandard ? '6.5rem' : '3rem',
    fontSize: isStandard ? '.875rem' : '.75rem', // As per figma
    background: isPrimary ? theme.palette.background.saveGreen : 'inherit',
    '&:hover': {
      color: isPrimary
        ? theme.palette.text.primary
        : theme.palette.text.secondary,
      background: isPrimary ? 'inherit' : theme.palette.text.primary,
    },
    '&:disabled': {
      color: isPrimary
        ? theme.palette.text.lightYellowishGray
        : theme.palette.background.darkGrey,
      background: isPrimary ? theme.palette.background.darkGrey : 'transparent',
      border: `0.0625rem solid ${
        isPrimary
          ? theme.palette.border.lightRaven
          : theme.palette.background.darkGrey
      }`,
    },
    textTransform: 'none',
  };
});
