import { globalstate$ } from '@smf/ui-util-app';
import { useEffect, useState } from 'react';

export const updateRxjsState = (data) => {
  const state = globalstate$.getValue();
  globalstate$.next({ ...state, ...data });
};

export const useRxjsState = () => {
  const [rxjsState, setState] = useState({});

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (globalstate$) {
      const subscription = globalstate$.subscribe((globalstate) => {
        setState(globalstate);
      });
      return () => {
        if (subscription) {
          subscription.unsubscribe();
        }
      };
    }
  }, []);

  return { rxjsState };
};
