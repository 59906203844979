import { StrictMode } from 'react';
import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { theme } from '@smf/ui-util-app';
import ConfigContainer from './containers/ConfigContainer';
import { ToastProvider } from './context/toastContext';

export default function Root() {
  const queryClient = new QueryClient();

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <ConfigContainer />
          </ToastProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </StrictMode>
  );
}
